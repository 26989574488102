.drug-contain {
  width: auto;
  height: auto;
  padding: 130px 0px 30px 0px;
  background-color: #fff;
  margin: 0 100px;
}

.drug-list {
  width: 100%;
  height: auto;
}
.drug-title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #525252;
  margin: 20px;
  border-bottom: 1px solid #e3e3e3;
}

.drug-item {
  width: 16%;
  height: auto;
  border-radius: 10px;
  background-color: #fff;
  display: inline-block;
  margin: 2%;
  margin-top: 0px;
}

.drug-item .drug-img {
  width: 100%;
  height: auto;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.drug-item .drug-name {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
}

.clear {
  clear: both;
}
