.drug-contain {
  width: auto;
  height: auto;
  padding: 90px 0px 30px 0px;
  background-color: #fff;
  margin: 0 100px;
}

.disease-list {
  width: 100%;
  height: auto;
}
.disease-title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #525252;
  margin: 20px;
  border-bottom: 1px solid #e3e3e3;
}

.disease-item {
  width: 21%;
  height: auto;
  border-radius: 10px;
  background-color: #fff;
  display: inline-block;
  margin: 2%;
  margin-top: 0px;
}

.disease-item .disease-img {
  width: 100%;
  height: auto;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.disease-item .disease-name {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
}

.disease-tag {
  height: 26px;
  display: inline-block;
  padding: 3px 8px;
  background: #fbb507;
  color: #fff;
  font-size: 12px;
  line-height: 22px;
  border-radius: 5px;
  margin-left: 20px;
  vertical-align: 2px;
}

.disease-item .disease-desc {
  width: 100%;
  height: 100px;
  line-height: 25px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 4;
}

.clear {
  clear: both;
}
