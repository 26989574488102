.home-main{
    padding-top: 60px;
}
.home-title img{
    width: 100%;
    height: 100%;
}
.con-title{
    text-align: center;
    margin: 50px 0;
    font-size: 30px;
    position: relative;
}
.con-title::after{
    position: absolute;
    content:'';
    height: 2px;
    background-color: #333;
    width: 140px;
    bottom: -8px;
    left: 50%;
    margin-left: -70px;
}
.title2::after{
    width: 100px;
    margin-left: -50px;
}
.item-box{
    display: flex;
}
.item-box ul{
    width: 30%;
    margin: 0 1.5%;
}
.item-box img{
    width: 100%;
}
li{
    list-style: none;
    white-space: nowrap;
    overflow: hidden;

 }
.tit{
    font-size: 14px;
    font-weight: 700;
    margin: 10px 0;
}
.banner img{
    width: 100%;
}