.recharge-contain {
    width:auto;
    height: auto;
    padding:10px 20px 50px 20px;
    background-color: #fff;
    margin:20px 100px 0px 100px;
}

.head-title{
    width:auto;
    height:40px;
    line-height: 40px;
    font-size:18px;
    color:#525252;
}

.head-desc{
    width:auto;
    height:20px;
    line-height: 20px;
    font-size:13px;
    color:#808080;
}

.game-account{
    width:auto;
    height:50px;
    margin-top:50px;
}

.input-account{
    width:300px;
    height:32px;
}


.select-game{
    width:auto;
    height:40px;
    margin-top:40px;
}

.select-game-option{
    width:300px;
    height:40px;
}

.recharge-money{
    width:auto;
    height:auto;
    margin-top:40px;
}

.recharge-money-title{
    width:auto;
    height:30px;
    line-height: 30px;
    font-size:14px;
    color:#525252;
}

.recharge-money-list{
    width:auto;
    height:auto;
}

.money-item{
    width:100px;
    height:46px;
    line-height: 46px;
    font-size:16px;
    color:#525252;
    text-align: center;
    margin-right:30px;
    margin-top:20px;
    border:1px solid #808080;
    -webkit-border-radius:5px ;
    -moz-border-radius: 5px;
    border-radius: 5px;
    float:left;
}

.other-money{
    width:100%;
    height:50px;
    margin-top:50px;
}

.input-money{
    width:300px;
    height:32px;
}


.btn-group{
    width:100%;
    height:50px;
    margin-top:50px;
}

.wx-pay-btn{
    width:150px;
    height:40px;
    color:#fff;
    line-height: 40px;
    text-align:center;
    background-color: #2aa515;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    float:right;
    margin-left:50px;
}

.ali-pay-btn{
    width:150px;
    height:40px;
    color:#fff;
    line-height: 40px;
    text-align:center;
    background-color: #1296db;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    float:right;

}

.clear{
    clear: both;
}
