.match-detail-contain {
    width:auto;
    height: auto;
    background: #fff;
    margin:0px 100px;
    padding:30px 60px 60px 60px;
}

.match-detail-title {
    width:auto;
    height: 80px;
    font-size:18px;
    font-weight: 700;
    line-height: 80px;
    margin-left: -20px;
}

.match-detail-title:before{
    width:18px;
    height: 18px;
    content:"";
    display: inline-block;
    background: url(../../static/image/match_icon.png) 0/ 100% 100%;
    vertical-align: middle;
    margin-top:-3px;
    margin-right:4px;
}

.desc-pic{
    width:60%;
    height:auto;
    margin: auto;

}

.desc-pic img{
    width:100%;
    height:auto;
    display: block;
    margin-top:20px;
}

.desc-pic-title{
    width:auto;
    height: 40px;
    font-size:12px;
    line-height: 40px;
    text-align: center;
    margin-bottom:10px;
}

.match-desc{
    width:auto;
    height: auto;
    font-size:14px;
    line-height: 30px;
}

p{
    width:auto;
    height: auto;
    font-size:14px;
    line-height: 26px;
}
