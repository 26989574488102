.modal-bg{
    height:100%;
    width:100%;
    top:0px;
    left:0px;
    position: fixed;
    background-color: #000;
    opacity: 0.5;
    z-index: 1111;
}

.vip-modal{
     height:500px;
     width:600px;
     top:50%;
     left:50%;
     position: fixed;
     margin-top:-280px;
     margin-left:-300px;
     background-color: #fff;
     z-index: 1112;
 }

.modal-head{
    height:70px;
    width:100%;
    background: url("../../static/image/modal_head_bg.png") 0/ 100% 100%;
    position: relative;
}

.modal-title{
    height:auto;
    width:180px;
    position: absolute;
    top:0px;
    left:20px;
}

.modal-title-img{
    height:auto;
    width:180px;
}

.modal-close{
    height:30px;
    width:30px;
    position: absolute;
    top:4px;
    right:4px;
}

.modal-close-img{
    height:16px;
    width:16px;
    margin-top:7px;
    margin-left:7px;
    display: block;
}

.modal-content{
    height:420px;
    width:100%;
}

.money-list-title{
    height:40px;
    width:auto;
    margin:0px 20px;
    font-size:13px;
    line-height: 40px;
    color:#525252;
    text-align: right;
    border-bottom:1px solid #f0f0f0;
}

.money-list{
    height:190px;
    width:600px;
}


.vip-select-item{
    width:110px;
    height:150px;
    line-height: 46px;
    font-size:16px;
    color:#525252;
    text-align: center;
    margin:20px;
    border:1px solid #808080;
    -webkit-border-radius:5px ;
    -moz-border-radius: 5px;
    border-radius: 5px;
    float:left;
    position: relative;
}


.vip-select-title{
    width:60%;
    height:40px;
    line-height: 40px;
    font-size:14px;
    color:#808080;
    text-align:center;
    margin:auto;
    margin-top:10px;
}


.vip-select-money{
    width:100%;
    height:40px;
    line-height: 40px;
    font-size:28px;
    color: #dea418;
    text-align:center;
}

.vip-select-desc{
    width:100%;
    height:60px;
    line-height: 22px;
    font-size:12px;
    color: #808080;
    text-align:center;
    padding:0px 10px;
}


.vip-select-icon{
    width: 30px;
    height: 30px;
    bottom: 0px;
    right: -2px;
    position: absolute;
    display: none;
}

.vip-select-icon img{
    width:30px;
    height:30px;
    display: block;
}


.pay-mode-info{
    height:170px;
    position: relative;
    border:1px solid #f0f0f0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin:10px;
}

.vip-pay-mode{
    height:170px;
    width:150px;
    top:0px;
    left:0px;
    position: absolute;
    background-color: #f0f0f0;
}

.vip-pay-wx{
    height:50px;
    width:150px;
    top:0px;
    left: 0px;
    position: absolute;
    border-bottom: 1px solid #f0f0f0;
    background-color: #f0f0f0;
}

.vip-pay-ali{
    height:50px;
    width:150px;
    top:50px;
    left: 0px;
    position: absolute;
    border-bottom: 1px solid #f0f0f0;
    background-color: #f0f0f0;
}

.pay-icon{
    height:50px;
    width:50px;
    top:0px;
    left:0px;
    position: absolute;
}

.pay-icon-img{
    height:30px;
    width:30px;
    display: block;
    margin-top:10px;
    margin-left:10px;
}

.pay-mode-name{
    height:50px;
    width:95px;
    top:0px;
    left:55px;
    line-height: 50px;
    font-size:14px;
    position: absolute;
}



.vip-pay-qrcode{
    height:170px;
    width:450px;
    top:0px;
    left:150px;
    position: absolute;
}

.vip-code{
    height:150px;
    width:150px;
    top:0px;
    left:80px;
    position: absolute;
}

.scan-desc{
    height:30px;
    width:150px;
    font-size:12px;
    text-align: center;
    line-height: 30px;
    color:#808080;
    top:135px;
    left:80px;
    position: absolute;
}

.vip-code-img{
    height:110px;
    width:110px;
    margin-top:20px;
    margin-left: 20px;
    position: absolute;
}

.vip-code-info{
    height: 60px;
    width: 150px;
    top: 50px;
    left: 230px;
    font-size:24px;
    color:#faad14;
    line-height: 60px;
    position: absolute;
}

.qa-title{
    height:50px;
    width:auto;
    margin:0px 20px;
    font-size:17px;
    line-height: 50px;
    color:#525252;
    border-bottom:1px solid #f0f0f0;
}

.qa-content{
    height:auto;
    width:auto;
    margin:0px 30px;
}


.qa-sub-title{
    height:auto;
    width:auto;
    font-size:15px;
    line-height: 30px;
    color:#525252;
    font-weight: 600;
    margin-top:26px;
}
.qa-sub-content{
     height:auto;
     width:auto;
     font-size:13px;
     line-height: 24px;
     color:#808080;
 }
