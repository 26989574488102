.tv-contain {
  width:auto;
  height: auto;
  padding:66px 0px 30px 0px;
  background-color: #fff;
  margin:0 100px;
}

.play-title {
  width:auto;
  height: 50px;
  font-size:18px;
  font-weight: 700;
  line-height: 50px;
  margin-left: 2%;
  margin-top:10px;
}


.play-title:before{
  width:18px;
  height: 18px;
  content:"";
  display: inline-block;
  background: url(../../static/image/preform_icon.png) 0/ 100% 100%;
  vertical-align: middle;
  margin-top:-3px;
  margin-right:4px;
}

.tv-play-list{
  width:100%;
  height: auto;
}

.tv-play-list-item{
  width:14.3%;
  height: 100px;
  display: inline-block;
  margin:1%;
}

.tv-play-pic{
  width:100%;
  height:auto;
  min-height:80px;
  position: relative;
}
.tv-play-img{
  width:100%;
  height:auto;
  display: block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.vip-icon{
  width:28px;
  top:8px;
  right: 8px;
  position:absolute;
  background-color: #fff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.vip-icon-img{
  width:28px;
  display: block;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.tv-play-title {
  width:100%;
  height: 36px;
  font-size:14px;
  line-height: 36px;
  font-weight: 700;
  overflow: hidden;
}

.tv-play-sTitle {
  width:100%;
  height: 20px;
  font-size:13px;
  line-height: 20px;
  overflow: hidden;
  color:#808080;
}




