.music-contain {
  height: auto;
  padding:30px 40px 100px 30px ;
  background-color: #fff;
  margin:0px 100px;
}

.music-title{
  height: 40px;
  width:auto;
  font-size:18px;
  color:#000;
}

.music-title:before{
  width:24px;
  height: 24px;
  content:"";
  display: inline-block;
  background: url(../../static/image/music_list_icon.png) 0/ 100% 100%;
  vertical-align: middle;
  margin-top:-3px;
  margin-right:4px;
}


.music-pic{
  width:100%;
  height: 400px;
  text-align: center;
}
.music-pic-img{
  height: 300px;
  width: 300px;
  margin-top:50px;
}

.music-btn-group img{
  width:22px;
  height: 22px;
  display: block;
}


.music-audio{
  height: 50px;
  bottom: 10px;
  left:180px;
  right:180px;
  position: fixed;
  z-index: 1000;
}
.music-audio-panel{
  height: 50px;
  width: 100%;
}


.ant-list-item{
  padding:0px;
}

.ant-list-split .ant-list-item {
  border:none;
}



.audio-panel{
  height: 50px;
  bottom: 150px;
  left:180px;
  right:180px;
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  border-radius: 24px;
  border: 1px solid #ff7474;
}

.audio-btn-group{
  height:50px;
  width:120px;
  position: absolute;
  top:0px;
  left:0px;
}



.audio-pre-btn{
  height:40px;
  width:30px;
  position: absolute;
  top:5px;
  left:10px;
}

.audio-play-btn{
  height:40px;
  width:30px;
  position: absolute;
  top:5px;
  left:50px;
}


.audio-play-btn img{
  height: 20px;
  width: 20px;
  margin-top: 10px;
  margin-left: 5px;
}

.audio-pre-btn img,
.audio-next-btn img{
  height:16px;
  width:16px;
  margin-top:12px;
  margin-left:6px;
}


.audio-next-btn{
  height:40px;
  width:30px;
  position: absolute;
  top:5px;
  left:90px;
}


.music-loop{
  height:40px;
  width:30px;
  position: absolute;
  top:5px;
  left:130px;
}



.music-loop img{
  height: 20px;
  width: 20px;
  margin-top: 10px;
  margin-left: 5px;
}


.current-time{
  height:20px;
  width:40px;
  position: absolute;
  top:15px;
  left:175px;
  font-size:15px;
  color:#ff7474;
  line-height: 20px;
  margin-left:-5px;
}


.duration-time{
  height:20px;
  width:40px;
  position: absolute;
  top:15px;
  right:10px;
  font-size:15px;
  color:#ff7474;
  line-height: 20px;
}


.progress{
  height:8px;
  width:auto;
  background-color: #808080;
  position: absolute;
  top:22px;
  left:220px;
  right:60px;
  border-radius:4px;
}

.panel-music-name{
  height:8px;
  width:auto;
  position: absolute;
  top:0px;
  left:222px;
  right:50px;
  font-size:12px;
  color:#525252;
  line-height: 20px;
}


.range{
  height:8px;
  width:0px;
  background-color: #ff7474;
  position: absolute;
  top:0px;
  left: 0px;
  border-radius:3px;
}



.circle{
  height:8px;
  width:8px;
  background-color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top:0px;
  left:3px;
  position: absolute;
  margin-left:-3px;
  display: none;
}


.music-voice{
  height:40px;
  width:40px;
  position: absolute;
  top:5px;
  right:10px;
  background-color: #ff7474;
  z-index:1111;
  display: none;
}

.music-voice-panel{
  height:100px;
  width:40px;
  position: fixed;
  bottom:80px;
  right:190px;
  background-color: #ff7474;
  z-index:1111;
  display: none;
}

