.disease-contain {
  width: auto;
  height: auto;
  padding: 130px 0px 30px 0px;
  background-color: #fff;
  margin: 0 100px;
}

.disease-list {
  width: 100%;
  height: auto;
}

.clear {
  clear: both;
}
