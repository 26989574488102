.preform-contain {
  width:auto;
  height: auto;
  padding:66px 0px 30px 0px;
  background-color: #fff;
  margin:0 100px;
}

.preform-list{
  width:86%;
  height: auto;
  margin:auto;
}

.loading-container {
  height: 30px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.preform-item{
  width:100%;
  min-height:150px;
  position: relative;
  margin-top:40px;
}

.preform-pic{
   width:40%;
   height: auto;
   display: inline-block;
 }

.preform-pic img{
  width:100%;
  height: auto;
  display: block;
}

.preform-info{
  width:57%;
  height: 100%;
  margin-left:3%;
  top:0px;
  right: 0px;
  bottom:0px;
  position: absolute;
}

.preform-info .preform-name{
  width:100%;
  height: 30px;
  font-size:16px;
  font-weight: 700;
}

.preform-name:before{
  width:18px;
  height: 18px;
  content:"";
  display: inline-block;
  background: url(../../static/image/preform_icon.png) 0/ 100% 100%;
  vertical-align: middle;
  margin-top:-3px;
  margin-right:4px;
}

.preform-info .preform-summary-info{
  width:100%;
  padding:3%;
  border:1px solid #f0f0f0;
  border-radius:10px;
  top:30px;
  bottom:6px;
  position: absolute;
  overflow: hidden;
  box-sizing:border-box;
}

.preform-summary-info .summary-title{
  width:100%;
  height: 30px;
  font-size:14px;
}

.preform-summary-info .preform-summary{
  width: auto;
  height: auto;
  line-height: 20px;
  font-size: 12px;
  top: 40px;
  bottom: 50px;
  right: 10px;
  left:10px;
  position: absolute;
  overflow: hidden;
}

.preform-btn-group{
  height:40px;
  width:100%;
  bottom:0px;
  left: 0px;
  position: absolute;
}


.preform-detail-btn{
  height:30px;
  width: 80px;
  line-height: 30px;
  text-align: center;
  font-size:14px;
  color:#fff;
  background-color: #ff7474;
  bottom:10px;
  right:10px;
  position: absolute;
  border-radius: 6px;
}

.preform-buy-btn{
  height:30px;
  width: 80px;
  line-height: 30px;
  text-align: center;
  font-size:14px;
  color:#fff;
  background-color: #F4A460;
  bottom:10px;
  right:110px;
  position: absolute;
  border-radius: 6px;
}

.clear{
  clear: both;
}

