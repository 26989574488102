.comic-contain {
  width:auto;
  height: auto;
  padding:90px 0px 0px 0px;
  background-color: #f0f2f5;
  margin:0 100px;
  }
  .comic-list{
    width:86%;
    height: auto;
    margin:auto;
  }
  
  .comic-item{
    width:31%;
    height: auto;
    background-color: #fff;
    display: inline-block;
    border-radius:2px;
    margin-right: 2%;
    margin-bottom:3%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    transition: all 0.2s;
  }
  .comic-item:hover{
    transform: scale(1.02);
  }
  .comic-pic{
     width:100%;
     height: 60%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 10px;
   }
  .comic-pic img{
    width:100%;
    height: auto;
  }
  .comic-info{
    height: 30%;
    padding: 0 20px 20px 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
  }
  .comic-name{
    height: 60px;
    font-size:20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .comic-desc{
    font-size:14px;
    color:#525252;
    text-align: center;
    overflow: hidden;
  }
  