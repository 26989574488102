.game-contain {
  width:auto;
  height: auto;
  padding:66px 0px 30px 0px;
  background-color: #fff;
  margin:0 100px;
}

.game-list{
  width:86%;
  height: auto;
  margin:auto;
  overflow: auto;
}

.loading-container {
  height: 30px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.game-item{
  width:100%;
  height: auto;
  margin-top:30px;
}

.game-item .game-info{
  width:100%;
  height: 80px;
  position: relative;
}

.game-info .game-thum-icon{
   width:70px;
   height: 70px;
   top: 5px;
   left: 0px;
   position: absolute;
 }

.game-thum-icon img{
  width:70px;
  height: 70px;
}

.game-info .game-name{
  width:auto;
  height: 30px;
  line-height: 30px;
  font-size:16px;
  top:0px;
  left: 100px;
  right:200px;
  position: absolute;
}

.game-info .game-desc{
  width:auto;
  height: 40px;
  line-height: 20px;
  font-size:13px;
  color: #747474;
  top:40px;
  left: 100px;
  right:200px;
  position: absolute;
  overflow: hidden;
}

.charge-btn{
  width:80px;
  height: 30px;
  line-height: 30px;
  font-size:15px;
  color:#fff;
  text-align: center;
  top:25px;
  right: 100px;
  position: absolute;
  background-color: #ff7474;
  border-radius: 6px;
}


.download-btn{
  width:80px;
  height: 30px;
  line-height: 30px;
  font-size:15px;
  color:#fff;
  text-align: center;
  top:25px;
  right: 0px;
  position: absolute;
  background-color: #43CD80;
  border-radius: 6px;
}

.game-pic-list{
  width:100%;
  height: auto;
  margin-top:10px;
}

.game-pic-item {
  width:32%;
  height: auto;
  margin:1% ;
  display: inline-block;
}

.game-pic-item{
  width:100%;
  height: auto;
  display: block;
}