.product-contain {
  padding-top:66px;
  background: #fff;
  margin:0px 100px;
}
.cate-list{
  width:100%;
  height: auto;
}
.cate-title{
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #525252;
  margin: 20px;
  border-bottom: 1px solid #e3e3e3;
}

.cate-item{
  width:16%;
  height: auto;
  border-radius:10px;
  background-color: #fff;
  display: inline-block;
  margin:2%;
  margin-top:0px;
  transition: all 0.2s;
}

 .cate-img{
  width:100%;
  height: auto;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.cate-item:hover{
  transform: scale(1.05);
}

.cate-name{
  width:100%;
  text-align:center;
  font-size:14px;
}

.ant-carousel .slick-slide {
  text-align: center;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

a{
  color:#525252;
}



