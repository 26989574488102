.detail-contain {
  background: #fff;
  margin:0px 100px;
  padding:0px 80px;
}

.comic-title {
  width:auto;
  height: 80px;
  font-size:18px;
  font-weight: 700;
  line-height: 80px;
  margin-left: -30px;
}


.comic-title:before{
  width:20px;
  height: 20px;
  content:"";
  display: inline-block;
  background: url(../../static/image/comic_icon.png) 0/ 100% 100%;
  vertical-align: middle;
  margin-top:-3px;
  margin-right:6px;
}

.pic-swiper{
  width: 50%;
  height:auto;
  margin:auto;
}


.detail-desc{
  height:auto;
  width:100%;
  line-height: 30px;
  font-size:14px;
  line-height: 30px;
  color:#525252;
  padding:20px 0px 40px 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}



