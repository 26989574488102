.foot{
    height:auto;
    width:100%;
    font-size:16px;
    color:#fff;
    line-height: 20px;
    text-align:center;
    padding:16px 0px;
    background-color: #525252;
}
