body {
  margin: auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-color: #f0f0f0;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.ant-layout-header {
  height: 66px;
  padding: 0 100px;
  line-height: 64px;
  background: #fff;
  z-index:1000;
  border-bottom:1px solid #f0f0f0;
}

.ant-layout-content {
  padding: 0px;
  -webkit-flex: auto;
  flex: auto;
  min-height: 0;
}

.ant-menu-item{
  font-size:16px;
}
.ant-menu-item-selected{
  background: #fff;
  color:blue;
}

.ant-menu-horizontal{
  borde:none;
}
