.head-nav{
    height:70px;
    width:100%;
    border-bottom:1px solid #e3e3e3;
    background-color: #f0f0f0;
    position: fixed;
    top:0px;
    left:0px;
    z-index:1000

}

.logo-section{
    height:70px;
    width:140px;
    border-bottom:1px solid #e3e3e3;
    background-color: #ff7474;
}

.nav-menu{
    height:70px;
    width:auto;
    top:0px;
    left:140px;
    right:0px;
    position: absolute;
}



.nav-menu ul{
    width: 100%;
    height:100%;
    list-style-type: none;
    padding:0px;
    margin:0px;
}

.nav-menu ul li{
    width: 100px;
    height: 70px;
    box-sizing:border-box;
    float:left;
}

li .item{
    width:100px;
    height:64px;
    line-height: 66px;
    font-size: 16px;
    color: #525252;
    text-align:center;
}

li .select-item{
    width:100px;
    height:64px;
    line-height: 66px;
    font-size: 16px;
    color:#1E90FF;
    text-align:center;
}

li .under-line{
    width:60px;
    height:4px;
    margin: auto;
    background-color:#f0f0f0;
}

li .select-under-line{
    width:60px;
    height:4px;
    margin: auto;
    background-color:#00CD66;
}

.logo {
    width: 360px;
    height: 55px;
    margin: 6px 24px 2px 0;
    float: left;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
}

.logo img{
    width: 360px;
    height: 55px;
    display: block;
}

.vip-buy{
    width: 110px;
    height: 36px;
    position:absolute;
    top:15px;
    right:240px;
    text-align: center;
    line-height: 36px;
    color:#fff;
    font-size:14px;
    background-color: #dea418;
    border-radius: 4px;
}

.register-login{
    width: 120px;
    height: 66px;
    position:absolute;
    top:0px;
    right:100px;
 }

.login{
    width: 60px;
    height: 66px;
    float:right;
    line-height: 66px;
    text-align:center;
    color:#525252;
    fong-size:16px;
}

.register{
    width: 60px;
    height: 66px;
    float:right;
    line-height: 66px;
    text-align:center;
    color:#525252;
    fong-size:16px;
}
