.match-contain {
    width:auto;
    height: auto;
    background: #fff;
    margin:0px 60px;
    padding:80px;
}

.match-title {
    width:auto;
    height: 60px;
    font-size:18px;
    font-weight: 700;
    line-height: 60px;
    margin-left: -30px;
}

.match-title:before{
    width:18px;
    height: 18px;
    content:"";
    display: inline-block;
    background: url(../../static/image/match_icon.png) 0/ 100% 100%;
    vertical-align: middle;
    margin-top:-3px;
    margin-right:4px;
}

.match-pic{
    width:60%;
    height:auto;
    margin: auto;

}

.match-pic img{
    width:100%;
    height:auto;
    display: block;
}

.desc-pic-title{
    width:auto;
    height: 40px;
    font-size:12px;
    line-height: 40px;
    text-align: center;
    margin-bottom:10px;
}

.match-desc{
    width:auto;
    height: auto;
    font-size:14px;
    line-height: 30px;
}

p{
    width:auto;
    height: auto;
    font-size:14px;
    line-height: 26px;
    margin-top:20px;
}

.detail_btn{
    height:30px;
    width:80px;
    line-height: 30px;
    font-size:14px;
    text-align: center;
    background-color:#ff7474;
    border-radius:6px;
    color:#fff;
    float:right;
}

