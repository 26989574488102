.buy-contain {
    width:auto;
    height: auto;
    padding:10px 20px 50px 20px;
    background-color: #fff;
    margin:20px 100px 0px 100px;
}

.head-title{
    width:auto;
    height:40px;
    line-height: 40px;
    font-size:18px;
    color:#525252;
}

.head-desc{
    width:auto;
    height:20px;
    line-height: 20px;
    font-size:13px;
    color:#808080;
}

.buy-money{
    width:auto;
    height:auto;
    margin-top:20px;
}

.buy-money-title{
    width:auto;
    height:30px;
    line-height: 30px;
    font-size:14px;
    color:#525252;
}

.buy-money-list{
    width:720px;
    height:auto;
    margin:auto;
}

.select-item{
    width:120px;
    height:170px;
    line-height: 46px;
    font-size:16px;
    color:#525252;
    text-align: center;
    margin:30px;
    border:1px solid #808080;
    -webkit-border-radius:5px ;
    -moz-border-radius: 5px;
    border-radius: 5px;
    float:left;
    position: relative;
}


.select-title{
    width:60%;
    height:36px;
    line-height: 36px;
    font-size:14px;
    color:#fff;
    text-align:center;
    margin:auto;
    margin-top:24px;
    border-bottom:1px solid #808080;
}


.select-money{
    width:100%;
    height:62px;
    line-height: 62px;
    font-size:28px;
    color: #eac714;
    text-align:center;
}

.select-desc{
    width:100%;
    height:30px;
    line-height: 30px;
    font-size:13px;
    color: #fff;
    text-align:center;
}


.select-icon{
    width: 30px;
    height: 30px;
    bottom: 0px;
    right: -2px;
    position: absolute;
    display: none;
}

.select-icon img{
    width:30px;
    height:30px;
    display: block;
}


.pay-mode{
    height:auto;
    width:500px;
    margin:auto;
    margin-top:50px;
}

.pay-mode-item{
    height:300px;
    width:150px;
    margin:0px 50px;
    float: left;
}

.mode-title{
    width:100%;
    height:50px;
    line-height: 50px;
    font-size:14px;
    color:#525252;
    text-align: center;
}

.mode-qrcode{
    height:150px;
    width:150px;
}

.mode-qrcode img{
    height:150px;
    width:150px;
    display: block;
}


.clear{
    clear: both;
}
