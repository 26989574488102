.swiper-contain{
  height:auto;
  width:100%;
  position: relative;
}
.ant-carousel .slick-slide{
  height: 100% !important; 
}

.pic-list{
  height:auto;
  width:100%;
}

.pic-item{
  height:auto;
  width:100%;
}

.pic-pre{
  height:100%;
  width:100px;
  left:0px;
  top:0px;
  bottom:20px;
  position: absolute;
  z-index:10;
  -webkit-transition: opacity 0.8s;
  -moz-transition: opacity 0.8s;
  -ms-transition: opacity 0.8s;
  -o-transition: opacity 0.8s;
  transition: opacity 0.8s;
}

.go-prev-icon{
  height:50px;
  width:50px;
  top:50%;
  left:50%;
  position: absolute;
  margin-top:-35px;
  margin-left:-20px;
}


.pic-next{
  height:100%;
  width:100px;
  right:0px;
  top:0px;
  bottom:20px;
  position: absolute;
  z-index:10;
  -webkit-transition: opacity 0.8s;
  -moz-transition: opacity 0.8s;
  -ms-transition: opacity 0.8s;
  -o-transition: opacity 0.8s;
  transition: opacity 0.8s;
}

.go-next-icon {
  height:50px;
  width:50px;
  top:50%;
  right:50%;
  position: absolute;
  margin-top:-35px;
  margin-right:-20px;
}


.ant-carousel .slick-slide {
  text-align: center;
  height: auto;
  background: #fff;
  overflow: hidden;
}

.ant-carousel .slick-slide {
  color: #fff;
}


.ant-carousel .slick-dots li.slick-active button {
  height: 5px;
  width: 34px;
  background: #fff;
}

.ant-carousel .slick-dots li button {
  display: block;
  width: 24px;
  height: 5px;
  padding: 0;
  background: #525252;
  opacity: 1;
  font-size: 0;
  border: 0;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}